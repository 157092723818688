import { useSelector } from "react-redux";
import { RootState } from "../store";

export const usePosterReducers = () => {
    return useSelector((state: RootState) => ({
      ...state.combinedReducer.posterReducers,
      ...state?.combinedReducer?.countries,
      ...state?.combinedReducer?.distributionReducers,
      ...state?.combinedReducer?.emailSetupReducers,
      ...state?.combinedReducer?.credentialReducers,
      ...state?.combinedReducer?.customersReducers,
      ...state?.combinedReducer?.inventory_list,
      ...state?.combinedReducer?.projectsReducers,
      ...state?.combinedReducer?.fabricationReducers,
      ...state?.combinedReducer?.shopReducers,
      ...state?.combinedReducer?.stockTransferReducers,
      ...state?.combinedReducer?.proposalReducers,
      ...state?.combinedReducer?.logisticReducer,
      roles: state.combinedReducer.roles,
      external_user: state.combinedReducer.external_user,
      internal_user: state.combinedReducer.internal_user,
      locality_list: state.combinedReducer.locality_list,
      socketResponse: state.combinedReducer.posterReducers.socketResponse,
      country_list: state?.combinedReducer?.countries?.country_list,
      project_type: state?.combinedReducer?.project_type,
      scope_of_project: state?.combinedReducer?.scope_of_project,
      products:state?.combinedReducer?.products,
      supplier_type:state?.combinedReducer?.supplier_type,
      supplier:state?.combinedReducer?.supplier,
      size_spec:state?.combinedReducer?.size_spec,
      scope_of_work:state?.combinedReducer?.scope_of_work,
      grade:state?.combinedReducer?.grade,
      coating:state?.combinedReducer?.coating,
      tax:state?.combinedReducer?.tax,
      productLength:state?.combinedReducer?.productLength,
      productUnitService:state?.combinedReducer?.productUnitService,
      status_of_drawing:state?.combinedReducer?.status_of_drawing,
      user_data:state?.combinedReducer?.user_data,
      request_quotation:state?.combinedReducer?.distributionReducers?.request_quotation,
      get_request_quotation:state?.combinedReducer?.distributionReducers?.get_request_quotation,
      supplierList:state?.combinedReducer?.distributionReducers?.supplierList,
      sale_order_list:state?.combinedReducer?.distributionReducers?.sale_order,
      delivered_list:state?.combinedReducer?.distributionReducers?.delivered_list,
      stock_supply_delivered_list:state?.combinedReducer?.stockSupplyReducers,
      emailTemplate:state?.combinedReducer?.emailTemplateReducers,
      email_details:state?.combinedReducer?.distributionReducers?.email_details,
      deliveryBy: state?.combinedReducer?.deliveryByServiceReducers,
      logisticLocation: state?.combinedReducer?.logisticLocationServiceReducers,
      checklist:state?.combinedReducer?.checkist,
      packageList: state?.combinedReducer?.projectsReducers.packageList,
      proposal_list:state?.combinedReducer?.proposalReducers,
      logisticsList:state?.combinedReducer?.logisticReducer,
      dashboard_data:state?.combinedReducer?.dashboardReducers,
      listLogList: state?.combinedReducer?.projectsReducers.listLogList,
      revisionHistoryList: state?.combinedReducer?.projectsReducers.revisionHistoryList,
      drawingVersionList: state?.combinedReducer?.projectsReducers.drawingVersionList,
      searchList:state?.combinedReducer?.searchReducer.searchList,
      
      // emailTemplate:state?.combinedReducer?.credentialReducers,

    }));
  };