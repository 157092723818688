import { IStockTransferList, IInventoryTransferList } from "./types";

export const ActionTypes = {
  SET_STORE_STOCK_TRANSFER_LIST: "SET_STORE_STOCK_TRANSFER_LIST",
  SET_STORE_INVENTORY_TRANSFER_LIST:"SET_STORE_INVENTORY_TRANSFER_LIST",
  SET_STORE_STOCK_TRANSFER_DETAILS:"SET_STORE_STOCK_TRANSFER_DETAILS",
};

export const setStoreStockTransferList = (payload: IStockTransferList) => ({
  type: ActionTypes.SET_STORE_STOCK_TRANSFER_LIST,
  payload,
});
export const setStoreStockTransferDetails = (payload: IStockTransferList) => ({
  type: ActionTypes.SET_STORE_STOCK_TRANSFER_DETAILS,
  payload,
});


export const setStoreInventoryTransferList = (payload: IInventoryTransferList) => ({
  type: ActionTypes.SET_STORE_INVENTORY_TRANSFER_LIST,
  payload,
});
