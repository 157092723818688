
import { ActionTypes } from './action';
import { IStockTransferType } from './types';


const initialState: IStockTransferType = {
  stockTransferList:{
    items: [],
    totalCount: 0,
    optionList: [],
  },
  inventoryTransferList:{
    items: [],
    totalCount: 0,
    optionList: [],
  },
  stockTransferDetails:null,
};

const stockTransferReducers = (state: IStockTransferType = initialState, action): IStockTransferType => {
  switch (action?.type) {
    case ActionTypes.SET_STORE_STOCK_TRANSFER_LIST:{
      const optionsList = action?.payload?.items?.map((item, index)=>({
        ...item,
        label: item?.name,
        value: item?.id
      }));
      return {
        ...state,
        stockTransferList:{
          ...action.payload,
          optionList: optionsList
        }
      };
    }
    case ActionTypes.SET_STORE_INVENTORY_TRANSFER_LIST:{
      const optionsList = action?.payload?.items?.map((item, index)=>({
        ...item,
        label: item?.name,
        value: item?.id
      }));
      return {
        ...state,
        inventoryTransferList:{
          ...action.payload,
          optionList: optionsList
        }
      };
    }
    case ActionTypes.SET_STORE_STOCK_TRANSFER_DETAILS:{
      return{
        ...state,
        stockTransferDetails: action?.payload?action?.payload:initialState?.stockTransferDetails
      }
    }
    default:
      return state;
  }
};

export default stockTransferReducers;
